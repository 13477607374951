<template>
  <div class="hexperience container">
    <div class="two-column-grid">
      <div>
        <img class="image" src="https://res.cloudinary.com/hayche-network/image/upload/v1646928318/website/images/home/experience_pczhct.png" alt="hospitality experience" />
      </div>
      <div>
        <p class="span">THE HAYCHE NETWORK</p>
        <h1 class="sub-heading">We provide complete hospitality experience</h1>
        <p>
          Established in 2012, The Hayche Network has quickly become a leading
          hospitality management and recruitment agency, specializing in the
          training and supply of top industry professionals to the Hospitality
          industry.
        </p>
      </div>
    </div>
  </div>
</template>

<style scoped>
/* hospitality experience */
.hexperience {
  margin-top: 150px;
}

.hexperience .two-column-grid {
  display: grid;
  align-items: center;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  column-gap: 100px;
  row-gap: 30px;
  margin-bottom: 108px;
}

.hexperience .two-column-grid .image {
  width: 90%;
  border-radius: var(--border-radius);
  margin: 0 auto;
  object-fit: cover;
}

@media (max-width: 768px) {
  .hexperience .two-column-grid .image {
    width: 290px;
    height: 290px
  }
}

.hexperience .two-column-grid .bell-image {
}

.hexperience .two-column-grid .span {
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  line-height: 22px;
  letter-spacing: 0.2em;
  color: #1a1a1a;
  margin-bottom: 19px;
}

.hexperience .two-column-grid h1 {
  font-style: normal;
  font-weight: 500;
  font-size: 30px;
  letter-spacing: 0.01em;
  color: #000000;
  margin-bottom: 41px;
  position: relative;
}

.hexperience .two-column-grid h1::after {
  content: "";
  display: block;
  width: 85%;
  height: 5px;
  background: #d6c057;
  left: 0px;
  position: absolute;
}

@media (max-width: 768px) {
  .hexperience .two-column-grid h1 {
    font-size: 25px;
  }
}

.hexperience .two-column-grid p {
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  line-height: 22px;
  display: flex;
  align-items: center;
  letter-spacing: 0.01em;
  color: #1a1a1a;
  line-height: 22px;
}
</style>