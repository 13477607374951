<template>
  <main class="hero bg-video-wrap">
    <video id="videoElement" muted autoplay @ended="ended"></video>
    <div class="video-overlay">
      <div class="content">
        <section>
          
          <h1 class="mb-2">The Hayche Network</h1>
          <div class="mb-4">
          <p>Your one-stop source for everything hospitality</p>
          </div>
          <button class="center button-pulse">
            <a href="https://business.hayche.net">
              <span>Looking to Hire ?</span>
            </a>
          </button>
        </section>
      </div>
    </div>
  </main>
</template>

<script>

let nArray = [
  { image: "https://res.cloudinary.com/hayche-network/image/upload/v1646928478/website/videos/19_bnu7mc.jpg", video: "https://res.cloudinary.com/hayche-network/video/upload/v1646928444/website/videos/19_kbugbr.mp4" },
  { image: "https://res.cloudinary.com/hayche-network/image/upload/v1646928465/website/videos/18_wfccyl.jpg", video: "https://res.cloudinary.com/hayche-network/video/upload/v1646928443/website/videos/18_bxztjm.mp4" },
  { image: "https://res.cloudinary.com/hayche-network/image/upload/v1646928499/website/videos/1_hnivan.jpg", video: "https://res.cloudinary.com/hayche-network/video/upload/v1646928406/website/videos/1_quirus.mp4" },
  { image: "https://res.cloudinary.com/hayche-network/image/upload/v1646928481/website/videos/2_iqvld8.jpg", video: "https://res.cloudinary.com/hayche-network/video/upload/v1646928409/website/videos/2_hi9cbr.mp4" },
  { image: "https://res.cloudinary.com/hayche-network/image/upload/v1646928494/website/videos/3_r8bjfa.jpg", video: "https://res.cloudinary.com/hayche-network/video/upload/v1646928460/website/videos/3_sncdlz.mp4" },
  { image: "https://res.cloudinary.com/hayche-network/image/upload/v1646928473/website/videos/4_p2qv5h.jpg", video: "https://res.cloudinary.com/hayche-network/video/upload/v1646928385/website/videos/4_tslid2.mp4" },
  { image: "https://res.cloudinary.com/hayche-network/image/upload/v1646928476/website/videos/5_jelxti.jpg", video: "https://res.cloudinary.com/hayche-network/video/upload/v1646928419/website/videos/5_byglud.mp4" }
]

export default {
  data() {
    return {
      nArr: nArray,
      videoStart: 0,
      ended: this.playNext
    }
  },
  methods: {
    playVideo() {
      console.log(this.nArr)
      var videoPlayer = document.getElementById('videoElement');
      videoPlayer.poster = this.nArr[0].image
      videoPlayer.src = this.nArr[0].video
    },
    playNext() {
      try {
        let i = this.videoStart + 1
        if (i < this.nArr.length) {
          var videoPlayer = document.getElementById('videoElement');
          videoPlayer.poster = this.nArr[i].image
          videoPlayer.src = this.nArr[i].video
          this.videoStart = i
          console.log(i)
        } else {
          this.videoStart = 0
          i = this.videoStart
          var videoPlayer = document.getElementById('videoElement');
          videoPlayer.src = this.nArr[i].video
        }
      } catch (err) { }
    },
  },
  mounted() {
    this.playVideo()
  },
  computed: {

  }
}
</script>



<style scoped>
/* start of hero */
.hero {
  width: 100%;
  height: 100%;
  background-position: center;
}

.hero .content {
  width: 700px;
  height: 100%;
  margin: 0 auto;
  display: grid;
  place-items: center;
}

@media (max-width: 768px) {
  .hero .content {
    width: 100%;
    height: 100%;
    place-items: center;
  }
}

.hero .content h1 {
  font-style: normal;
  font-weight: 800;
  font-size: 90px;
  line-height: 100px;
  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: 0.01em;
  color: white;
}

.hero .content p {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 30px;
  text-align: center;
  letter-spacing: 0.5em;
  text-transform: uppercase;
  color: white;
  margin-bottom: 19px;
}

@media (max-width: 768px) {
  .hero .content h1 {
    font-size: 40px;
    line-height: 50px;
  }
}

.hero .content button {
  width: 187px;
  height: 52px;
  font-weight: 500;
  font-size: 16px;
  align-items: center;
  text-align: center;
  letter-spacing: 0.005em;
  color: #3a3a3c;
  border: none;
  border-radius: var(--button-border-radius);
  cursor: pointer;
  transition: background-color 2s, transform .6s;
}

.hero .content button:hover {
  transition: background-color 2s, transform .6s;
  transform: scale(1.1)
}

.bg-video-wrap {
  display: grid;
  position: relative;
}

video {
  width: 100%;
  min-height: 100vh;
  object-fit: cover;
  z-index: 1;
}

.video-overlay {
  width: 100%;
  height: 100%;
  position: absolute;
  display: grid;
  place-items: center;
  top: 0;
  left: 0;
  background-image: linear-gradient(
    45deg,
    rgba(0, 0, 0, 0.3) 50%,
    rgba(0, 0, 0, 0.7) 50%
  );
  background-size: 3px 3px;
  z-index: 2;
}

.video-overlay h1 {
  font-weight: bold;
  font-size: 80px;
  color: white;
  width: 100%;
  height: 100%;
  text-align: center;
}

@media (max-width: 768px) {
  .video-overlay h1 {
    font-weight: bold;
    font-size: 30px;
    color: white;
    width: 100%;
    text-align: center;
  }
}

.video-overlay img {
  width: 100%;
  margin: 0 auto;
}

@media (max-width: 768px) {
  .video-overlay img {
    width: 100%;
    margin: 0 auto;
  }
}
/* end of hero */
</style>