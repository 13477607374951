<template>
  <div>
    <section>
      <div class="mt-8">
        <section>
          <div class="whatwedo" id="services">
            <div>
              <p>WHAT WE DO</p>
              <h1>Our Lifestyle Management Services Include</h1>
            </div>
            <div>
              <img
                class="bell-image"
                src="https://res.cloudinary.com/hayche-network/image/upload/v1646928871/website/images/home/bell_wk08xb.png"
                alt="hospitality bell"
              />
            </div>
          </div>
        </section>

        <div class="mt-5">
          <div class="grid">
            <div class="grid-item" v-for="(i, index) in services" :key="index">
              <img :src="i.image" alt />
              <div class="content">
                <h5>{{ i.message }}</h5>
                <h1>
                  {{ i.title }}
                  <a v-if="i.link" :href="i.link">
                    <span style="font-size: 10px; color: var(--primary-hayche)"
                      >Visit Academy</span
                    >
                  </a>
                </h1>
                <p>{{ i.body }}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>


<script>
export default {
  data() {
    return {
      services: [
        {
          id: 1,
          message: "MAXIMIZING SERVICES AND MINIMIZING COST",
          title: "Hotel Operations",
          body: `A major challenge hospitality businesses face is how best to streamline operations. We are here to help with all areas of productivity management, food and beverage controls, and procurement. Our assessment tools are designed to monitor performance and generate reports and metrics about room yields utilization, labor and cash management, expense variances, and other key indicators on a daily or weekly basis.`,
          image:
            "https://res.cloudinary.com/hayche-network/image/upload/v1646928324/website/images/home/services/hotel-operations_egaq5a.png",
        },
        {
          id: 2,
          message: "INCREASING VALUE THROUGH PEOPLE",
          title: "Human Resources",
          body: `Our Human Resource services are integrated with The Hayche Academy. 
                The Academy supports our clients in the effective management of Human 
                Resources. No matter what type of property you manage, managing human 
                resources effectively is essential to the growth of your hotel.`,
          image:
            "https://res.cloudinary.com/hayche-network/image/upload/v1646928320/website/images/home/services/human-resources_uchiyy.png",
          link: "https://academy.hayche.net",
        },
        {
          id: 3,
          message: "HELPING CLIENTS WIN AND KEEP CUSTOMERS",
          title: "Sales & Marketing",
          body: `From tapping into strategic marketing partnerships to executing a 
                robust public relations campaign, the Hayche team is here to help your 
                establishment receive maximum exposure and connect with your target 
                market.`,
          image:
            "https://res.cloudinary.com/hayche-network/image/upload/v1646928321/website/images/home/services/sales-marketing_zt8ko8.png",
        },
        {
          id: 4,
          message: "WE DON’T COMPROMISE ON QUALITY",
          title: "Quality Control",
          body: `In compliance with the requirements for certification by NINAS/SON, a hotel 
          must meet or exceed the minimum standards of cleanliness, maintenance & security. 
          Our team of professionals using assessment tools developed by the Hayche Network 
          in compliance with NINAS/SON certification criteria, perform a free preliminary 
          on-site assessment for your facility. Thereafter we present a report of our findings 
          with recommendations for improvement.`,
          image:
            "https://res.cloudinary.com/hayche-network/image/upload/v1646928319/website/images/home/services/quality-control_a8ohoy.png",
        },
      ],
    };
  },
};
</script>


<style scoped>
.whatwedo {
  width: 100%;
  margin: 0 auto;
  display: grid;
  align-items: center;
  gap: 40px;
  grid-template-columns: 60% 40%;
}

.whatwedo h1 {
  font-style: normal;
  font-weight: normal;
  font-size: 35px;

  display: flex;
  align-items: center;
  letter-spacing: 0.01em;
  color: #000000;
  width: 572px;
}

.whatwedo p {
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  line-height: 22px;
  display: flex;
  align-items: center;
  letter-spacing: 0.2em;
  color: #1a1a1a;
  margin-bottom: 24px;
}

@media (max-width: 768px) {
  .whatwedo {
    display: grid;
    width: 100%;
    place-items: center;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    gap: 10px;
    margin-bottom: 108px;
  }

  .whatwedo h1 {
    width: 100%;
  }
}

.grid {
  display: grid;
  width: 100%;
  grid-template-columns: repeat(auto-fit, minmax(500px, 1fr));
  column-gap: 50px;
  row-gap: 30px;
  place-items: center;
}

.grid .grid-item {
  width: 530px;
  height: 500px;
  display: grid;
  position: relative;
  border-radius: var(--border-radius);
  box-shadow: 0px 4px 10px rgba(193, 193, 193, 0.15);
  margin: 0 auto;
}

@media (max-width: 768px) {
  .grid {
    display: grid;
    width: 100%;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    column-gap: 50px;
    row-gap: 50px;
    place-items: center;
  }

  .grid .grid-item {
    width: 100%;
    height: 490px;
    box-shadow: none;
    display: grid;
    position: relative;
    border-radius: var(--border-radius);
  }

  .grid .grid-item .content {
    margin: 10px !important;
  }
}

.grid .grid-item img {
  position: absolute;
  border-radius: 30px 30px 0px 0px;
  height: 175px;
  object-fit: cover;
  top: 0;
  left: 0;
}

.grid .grid-item .content {
  margin: 30px;
  top: 200px;
  position: absolute;
}

.grid .grid-item .content h5 {
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  line-height: 21px;
  align-items: center;
  letter-spacing: 0.2em;
  color: #1b1b1b;
  margin-bottom: 16px;
}

.grid .grid-item .content h1 {
  font-style: normal;
  font-weight: normal;
  font-size: 22px;
  line-height: 33px;
  align-items: center;
  letter-spacing: 0.01em;
  color: #1b1b1b;
  margin-bottom: 10px;
}

.grid .grid-item .content p {
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 19px;
  align-items: center;
  letter-spacing: 0.01em;
  color: #1b1b1b;
}
</style>