<template>
  <div class="mt-10">
    <section>
      <div class="network-services mb-5">
        <div class="mt-2 mb-5">
          <h1>Lifestyle</h1>
        </div>
        <div class="network-services-grid">
          <div class="grid-item" v-for="(i, index) in jobs" :key="index">
            <img :src="i.job_image" alt="platform image" />
            <h5>{{ i.job_title }}</h5>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
export default {
  data() {
    return {
      jobs: [
        {
          id: 1,
          job_image:
            "https://res.cloudinary.com/hayche-network/image/upload/v1646928327/website/images/lifestyle/travels_j1leij.png",
          job_title: "Travel / Destinations",
        },
        {
          id: 2,
          job_image:
            "https://res.cloudinary.com/hayche-network/image/upload/v1646928329/website/images/lifestyle/hotel_i8ikt3.png",
          job_title: "Hotel / Apartments",
        },
        {
          id: 3,
          job_image:
            "https://res.cloudinary.com/hayche-network/image/upload/v1646928332/website/images/lifestyle/food_vufc7u.png",
          job_title: "Food / Resaurants",
        },
        {
          id: 4,
          job_image:
            "https://res.cloudinary.com/hayche-network/image/upload/v1646928327/website/images/lifestyle/events_dzlbkg.png",
          job_title: "Events",
        },
      ],
    };
  },
};
</script>

<style scoped>
/* network services */

.network-services h1 {
  font-style: normal;
  font-size: 30px;
  line-height: 45px;
  text-align: center;
  letter-spacing: 0.01em;
  color: #1a1a1a;
  margin-bottom: 20px;
}

.network-services p {
  font-weight: normal;
  font-size: 15px;
  line-height: 22px;
  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: 0.01em;
  color: #000000;
  width: 495px;
  margin: 0 auto;
  margin-bottom: 67px;
}

@media (max-width: 768px) {
  .network-services p {
    width: 100%;
  }
}

.network-services .network-services-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  column-gap: 20px;
  row-gap: 50px;
  place-items: center;
}

.network-services .network-services-grid .grid-item {
  width: 250px;
  position: relative;
  transition: background-color 2s, transform 0.5s;
}

.network-services .network-services-grid .grid-item:hover {
  transform: scale(1.1);
  transition: background-color 2s, transform 0.5s;
}

.network-services .network-services-grid .grid-item img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: var(--border-radius);
  transition: background-color 2s, transform 0.5s;
}

.network-services .network-services-grid .grid-item img:hover {
  transition: background-color 2s, transform 0.5s;
  transform: rotate(-2deg);
}

.network-services .network-services-grid .grid-item h5 {
  font-style: normal;
  text-transform: uppercase;
  font-size: 11px;
  line-height: 21px;
  text-align: center;
  letter-spacing: 0.01em;
  color: #1c1c1c;
  width: 100%;
}
/* end of network services */
</style>