<template>
  <hero />
  <coreValues />
  <experience />
  <videoPlay />
  <whatwedo />
  <lifestyle />
  <reviews />
</template>

<script>
import hero from "./hero";
import coreValues from "./corevalues";
import experience from "./experience";
import videoPlay from "./video";
import whatwedo from "./whatwedo";
import lifestyle from "./lifestyle";
import reviews from "./reviews";

export default {
  components: {
    hero,
    coreValues,
    experience,
    videoPlay,
    whatwedo,
    lifestyle,
    reviews
  },
};
</script>

