<template>
  <div class="experience-grid">
    <div class="experience-item">
      <div>
        <img src="@/assets/icons/experience/healthy.svg" alt="Healthy" />
        <p>Healthy</p>
      </div>
    </div>

    <div class="experience-item">
      <div>
        <img
          src="@/assets/icons/experience/adaptability.svg"
          alt="Adaptability"
        />
        <p>Adaptability</p>
      </div>
    </div>

    <div class="experience-item">
      <div>
        <img src="@/assets/icons/experience/universal.svg" alt="Universal" />
        <p>Universal</p>
      </div>
    </div>
  </div>
</template>


<style scoped>
.experience-grid {
  width: 50%;
  display: grid;
  place-items: center;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  gap: 40px;
}

@media (max-width: 768px) {
  .experience-grid {
    width: 60%;
    margin-top: 1em;
    margin-bottom: 1em;
    gap: 10px;
  }
}

.experience-item {
  width: 100%;
  border: 1px solid rgba(255, 255, 255, 0.7);
  box-sizing: border-box;
  border-radius: 30px;
  padding: 50px;
}

.experience-item > div {
  display: grid;
  display: grid;
  place-items: center;
}

.experience-item img {
  width: 35px;
  height: 35px;
  margin-bottom: 1em;
  object-fit: cover;
}

.experience-item p {
  font-family: "Optima";
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 19px;
  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: 0.02em;
  color: rgba(255, 255, 255, 0.7);
}
</style>