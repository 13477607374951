<template>
  <div class="mt-10">
    <div class="reviews container">
      <h1>Hayche Reviews</h1>
      <p>
        Receive reviews of your businesses in several locations straight in your
        inbox. You’ve worked hard enough for this. Allow us monitor your growth
        & success.
      </p>
      <div class="grid">
        <div class="grid-item" v-for="(i, index) in services" :key="index">
          <span class="green-tag">Verified</span>
          <div class="content">
            <div class="wrapper">
              <img :src="i.avatar" alt />
              <div class="rating">
                <h1 v-if="i.rating !== 0">{{ i.rating }}</h1>
                <img v-if="i.rating === 0" class="star" src="@/assets/icons/no-medal.png" />
                <img v-if="i.rating > 0" class="star" src="@/assets/icons/medal.png" />
              </div>
            </div>
            <h1>{{ i.name }}</h1>
            <p>{{ i.message }}</p>
          </div>
        </div>
      </div>

      <a href="/reviews">
        <button class="center button-float" style="margin-top: 3em">
          Hayche Reviews
        </button>
      </a>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      services: [
        {
          id: 1,
          rating: 4,
          name: "Mega Star Hotels & Suite",
          message: `Hayche Network has established custom quality control services and systems to help our clients succeed. We perform surveys, audits.`,
          avatar: 'https://res.cloudinary.com/hayche-network/image/upload/v1646928319/website/images/home/reviews/mega_keetvt.png',
        },
        {
          id: 2,
          rating: 5,
          name: "Eko Hotels & Suite",
          message: `Hayche Network has established custom quality control services and systems to help our clients succeed. We perform surveys, audits.`,
          avatar: 'https://res.cloudinary.com/hayche-network/image/upload/v1646928315/website/images/home/reviews/eko_r3n1mw.png',
        },
        {
          id: 3,
          rating: 5,
          name: "Sheraton Hilton Hotels",
          message: `Hayche Network has established custom quality control services and systems to help our clients succeed. We perform surveys, audits.`,
          avatar: 'https://res.cloudinary.com/hayche-network/image/upload/v1646928319/website/images/home/reviews/sheraton_xljiel.png',
        },
      ],
    };
  },
};
</script>

<style scoped>
/* network services */
.reviews {
  margin-bottom: 155px;
}

.reviews > h1 {
  font-style: normal;
  font-size: 30px;
  line-height: 45px;
  text-align: center;
  letter-spacing: 0.01em;
  color: #1a1a1a;
  margin-bottom: 20px;
}

.reviews > p {
  font-weight: normal;
  font-size: 15px;
  line-height: 22px;
  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: 0.01em;
  color: #000000;
  width: 495px;
  margin: 0 auto;
  margin-bottom: 67px;
}

@media (max-width: 768px) {
  .reviews p {
    width: 100%;
  }
}

.grid {
  display: grid;
  width: 100%;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  column-gap: 30px;
  row-gap: 50px;
  place-items: center;
}

.grid .grid-item {
  width: 300px;
  height: 320px;
  position: relative;
  background: #ffffff;
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.05);
  border-radius: var(--border-radius);
  margin: 0 auto;
}

.grid .grid-item span {
  position: absolute;
  top: 12px;
  right: 12px;
}

@media (max-width: 768px) {
  .grid {
    display: grid;
    width: 100%;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    column-gap: 50px;
    row-gap: 50px;
    place-items: center;
  }

  .grid .grid-item {
    width: 100%;
    position: relative;
    border-radius: var(--border-radius);
    box-shadow: 0px 4px 10px rgba(193, 193, 193, 0.15);
  }

  .grid .grid-item .content {
    margin: 10px !important;
  }
}

.grid .grid-item .content .wrapper {
  display: flex;
  justify-content: space-between;
  margin-bottom: 36px;
}

.grid .grid-item img {
  border-radius: var(--border-radius);
  width: 79px;
  height: 79px;
  object-fit: cover;
}

.grid .grid-item .star {
  width: 40px;
  height: 40px;
  object-fit: contain;
}

.grid .grid-item .content {
  margin-left: 20px;
  margin-right: 30px;
  top: 50px;
  position: absolute;
}

.grid .grid-item .content .rating {
  display: grid;
  grid-template-columns: 1fr 1fr;
  width: 50px;
  align-items: center;
}

.grid .grid-item .content .rating h1 {
  font-weight: 300;
  font-size: 50px;
  line-height: 75px;
  align-items: center;
  letter-spacing: 0.01em;
  color: #252525;
}

.grid .grid-item .content h5 {
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  line-height: 21px;
  align-items: center;
  letter-spacing: 0.2em;
  color: #1b1b1b;
  margin-bottom: 16px;
}

.grid .grid-item .content h1 {
  font-style: normal;
  font-weight: normal;
  font-size: 22px;
  line-height: 33px;
  align-items: center;
  letter-spacing: 0.01em;
  color: #1b1b1b;
  margin-bottom: 10px;
}

.grid .grid-item .content p {
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 19px;
  align-items: center;
  letter-spacing: 0.01em;
  color: #1b1b1b;
}

button {
  width: 187px;
  height: 52px;
  font-weight: 300;
  font-size: 16px;
  align-items: center;
  text-align: center;
  letter-spacing: 0.005em;
  color: #3a3a3c;
  border: none;
  border-radius: var(--button-border-radius);
  cursor: pointer;
}
/* end of network services */
</style>