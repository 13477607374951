<template>
  <section>
    <div class="corevalue-grid">
      <div class="grid-item">
        <img
          src="https://res.cloudinary.com/hayche-network/image/upload/v1646928330/website/images/core-values/academy_qb65sw.png"
          alt="hayche academy"
        />
        <p>ACADEMY</p>
      </div>

      <div class="grid-item">
        <img
          src="https://res.cloudinary.com/hayche-network/image/upload/v1646928333/website/images/core-values/network_mumbax.png"
          alt="hayche academy"
        />
        <p>DIGITAL</p>
      </div>

      <div class="grid-item">
        <img
          src="https://res.cloudinary.com/hayche-network/image/upload/v1646928332/website/images/core-values/lifestyle_ghisup.png"
          alt="hayche academy"
        />
        <p>LIFESTYLE</p>
      </div>
    </div>
  </section>
</template>


<style scoped>
.corevalue-grid {
  margin-top: 100px;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  column-gap: 27px;
  row-gap: 40px;
  width: 100%;
}

.corevalue-grid .grid-item {
  border: none;
  box-sizing: border-box;
  width: 90%;
  margin: 0 auto;
  padding: 15px;
  border-radius: var(--border-radius);
  border: 1px solid rgba(0, 0, 0, 0.049);
  transition: background-color 2s, transform 1s;
}

.corevalue-grid .grid-item:hover {
  background-color: rgba(242, 242, 242, 0.699);
  transition: background-color 2s, transform 1s;
}
.corevalue-grid .grid-item:hover p {
}

.corevalue-grid .grid-item img {
  object-fit: cover;
  width: 100%;
  border-radius: var(--border-radius);
  margin-bottom: 20px;
  border: none;
  object-fit: cover;
}

.corevalue-grid .grid-item p {
  font-style: normal;
  font-weight: 300;
  font-size: 20px;
  line-height: 30px;
  align-items: center;
  letter-spacing: 0.2em;
  text-align: center;
  color: #1f1f1f;
  font-family: "Optima";
}
</style>